
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Field, ErrorMessage } from "vee-validate";
import { useStore } from "vuex";
import ElCurrency from "@/components/financial/CurrencyInput.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import JoinHolderInfo from "@/views/customers/JointHolderInfo.vue";

export default defineComponent({
  name: "customer-details",
  components: {
    Field,
    ErrorMessage,
    ElCurrency,
    JoinHolderInfo,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Profile", ["Customer"]);
    });

    return {};
  },
  data() {
    const store = useStore();
    const route = useRoute();
    const cstmr_id = route.params.id ?? null;
    const reverseMortgage = null;
    const rmCert = {} as any;
    const rmCertReady = false;
    const baseURL = process.env.VUE_APP_DOC_URL;

    const dataSetChecklist = {
      checklist_1: false,
      checklist_2: false,
      checklist_3: false,
      checklist_4: false,
      checklist_5: false,
      checklist_6: false,
      checklist_7: false,
      checklist_8: false,
      checklist_9: false,
      checklist_10: false,
      checklist_10_1: false,
      checklist_10_2: false,
      checklist_10_3: false,
      checklist_10_4: false,
      checklist_10_5: false,
      checklist_10_6: false,
      checklist_10_7: false,
      checklist_10_8: false,
      checklist_11: false,
    };

    const checkAll = false;

    const dataSetSSBAdvisor = {
      disclaimer: false,
      fa_comments: "",
      // interested: false,
    };

    return {
      store,
      route: useRoute(),
      cstmr_id,
      dataSetChecklist,
      dataSetSSBAdvisor,
      // personal: {},
      joint_customers: [],
      property_info: {},
      property: {} as any,
      checkAll,
      reverseMortgage,
      rmCert,
      rmCertReady,
      baseURL,
    };
  },
  methods: {
    init() {
      setTimeout(() => {
        this.getReverseMortgage();
        this.getProperty();
        this.getCertificate();
      }, 1000);
    },
    getProperty() {
      this.store
        .dispatch(Actions.GET_PROPERTY, this.route.params.id)
        .then(() => {
          this.property =
            this.store.getters.getPropertyData?.raw ?? ({} as any);
        });
    },
    getReverseMortgage() {
      this.store
        .dispatch(Actions.GET_REVERSE_MORTGAGE, this.route.params.id)
        .then(() => {
          this.reverseMortgage =
            Object?.keys(this.store.getters.getReverseMorgage)?.length !== 0
              ? this.store.getters.getReverseMorgage
              : null;
          this.dataSetChecklist =
            this.store.getters.getReverseMorgage?.raw?.checklists ??
            this.dataSetChecklist;
          this.dataSetSSBAdvisor =
            this.store.getters.getReverseMorgage.raw?.ssb_advisor ??
            this.dataSetSSBAdvisor;

          this.onCheck();
        });
    },
    getCertificate() {
      this.store
        .dispatch(Actions.GET_RM_CERTIFICATE, this.route.params.id)
        .then(() => {
          this.rmCert = this.store.getters.getRMCertificateData.data;


          // if (this.rmCert && this.rmCert.status == "Completed") {
          //   let url = process.env.VUE_APP_API_URL + "/" + this.rmCert.url; // ⛔️
          // }

          this.rmCertReady = true;
        });
    },
    onSavePropertyInfo() {
      this.store
        .dispatch(Actions.STORE_RAW_APPOINTMENT, {
          user_id: this.route.params.id,
          type: "property",
          raw: this.property,
        })
        .then(() => {
          Swal.fire({
            title: "Property Information has successfully updated.",
            icon: "success",
            heightAuto: false,
            allowOutsideClick: false,
          });
        });
    },

    onSubmitReverseMortgage() {
      this.store
        .dispatch(Actions.STORE_RAW_APPOINTMENT, {
          user_id: this.route.params.id,
          type: "reverse_mortgage",
          raw: {
            checklists: this.dataSetChecklist ?? {},
            ssb_advisor: this.dataSetSSBAdvisor ?? {},
          },
        })
        .then(() => {
          Swal.fire({
            title: "Customer Reverse Mortgage successfully updated.",
            icon: "success",
            heightAuto: false,
            allowOutsideClick: false,
          });
        });
    },

    onCheckAll() {
      Object.keys(this.dataSetChecklist).forEach((key) => {
        this.dataSetChecklist[key] = this.checkAll;
      });
    },

    onCheck() {
      this.checkAll = true;

      Object.keys(this.dataSetChecklist).forEach((key) => {
        if (!this.dataSetChecklist[key]) {
          this.checkAll = null;
        }
      });
    },
  },
  async mounted() {
    this.init();
  },
});
