
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import * as Yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ElLoading } from "element-plus";
// import upload from "element-plus/es/components/upload/src/ajax";
// import { Action } from "vuex-module-decorators";
// import { ElLoading } from "element-plus";

export default defineComponent({
  name: "joint-holder",
  props: ["propertyInfo"],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const formJointHolderValidator = Yup.object({
      jointHolder: Yup.object({
        nameT: Yup.string().required().label("Name"),
        nricT: Yup.string()
          .test("len", "Must be exactly 12 digits", (val) => val?.length === 12)
          .required()
          .label("NRIC"),
      }),
    });

    return {
      formJointHolderValidator,
    };
  },
  data() {
    const property = this.propertyInfo;

    const jointHolder = {
      nameT: "",
      nricT: "",
    };

    return {
      jointHolder,
      property,
    };
  },
  methods: {
    init() {
      // this.jointHolders.push(this.jointHolder);
    },

    onAddJointHolder(value, { resetForm }) {
      this.property.joinedHolders.push({
        name:this.jointHolder.nameT,
        nric:this.jointHolder.nricT
      });
      resetForm();
    },
    delJointHolder(index) {
      console.log(index)
      this.property.joinedHolders.splice(index,1);
    },
  },
  async mounted() {
    this.init();
  },
});
